<template>
  <div>
    <div class="row mt-4 mt-md-0">
      <div class="col-md-6 d-flex align-items-center">
        <h3 class="color-text f-600">Clientes</h3>
      </div>
      <div class="col-md-6 d-md-flex justify-content-end">
        <div class="position-relative as-icon">
          <InputText
            id="search"
            class="form-input"
            placeholder="Pesquisar..."
            type="text"
            v-model="filters['global']"
            required
          />
          <feather class="color-text is-18" type="search"></feather>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12" v-if="loading">
        <content-placeholders rounded>
          <content-placeholders-img />
          <content-placeholders-text :lines="4" />
        </content-placeholders>
      </div>

      <div class="col-12" v-if="!loading">
        <DataTable
          :value="clientes"
          :paginator="true"
          :rows="10"
          :filters="filters"
          dataKey="id"
          selectionMode="single"
          @row-select="getCliente"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          class="p-datatable-customers"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}"
        >
          <Column field="name" header="Nome" sortable></Column>
          <Column field="cnpj" header="CNPJ" sortable></Column>
          <Column field="cpf" header="CPF" sortable></Column>
          <Column field="tipo" header="Tipo" sortable></Column>
        </DataTable>
      </div>
    </div>
    <Dialog
      :header="'Cliente'"
      :visible.sync="displayModal"
      :style="{ width: '70vw' }"
      :modal="true"
    >
             <form v-on:submit.prevent="updateUser">
               <hr class="mb-4">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="username" class="fs-12 color-text"
                      >Nome Completo</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.name"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Nome de exibição no certificado</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.nome_cert"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Data de nascimento</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="date"
                      v-model="clienteSingle.nascimento"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="cpf" class="fs-12 color-text">CPF</label>
                    <InputText
                      id="cpf"
                      class="form-input"
                      type="text"
                      disabled
                      v-model="clienteSingle.cpf"
                      v-mask="['###.###.###-##']"
                    />
                  </div>
                                    <div class="form-group col-md-6">
                    <label for="cnpj" class="fs-12 color-text">CNPJ</label>
                    <InputText
                      id="cnpj"
                      class="form-input"
                      type="text"
                      disabled
                      v-model="clienteSingle.cpf"
                      v-mask="['##.###.###/####-##']"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Pronome que mais combina com você</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                        v-model="clienteSingle.pronome"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="celular" class="fs-12 color-text"
                      >Celular</label
                    >
                    <InputText
                      id="celular"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.celular"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Telefone de Contato</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.telefone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >E-mail de Contato</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.email"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Empresa</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.empresa"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text">Cargo</label>
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="clienteSingle.cargo"
                      disabled
                    />
          
                  </div>
                                    <div class="form-group col-md-12">
                    <button
                      type="submit"
                      class="btn btn-success flex-row d-flex justify-content-center align-items-center"
                      style="width: 250px"
                      :disabled="loading"
                    >
                      <div
                        class="spinner-grow text-light"
                        role="status"
                        v-if="loading"
                      ></div>
                      <span class="fs-14 text-uppercase"  v-if="!loading">Atualizar dados</span>
                    </button>
                  </div>
                </div>

              </form>

    </Dialog>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
export default {
  name: "Clientes",
  components: {
    DataTable,
    Column,
    Dialog
  },
  data() {
    return {
      filters: {},
      clientes: [],
      clienteSingle: {},
      loading: false,
      displayModal: false
    };
  },
  methods: {
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
        getCliente(event) {
          console.log(event)
      this.openModal()
      this.$http
        .get("/cliente?user_id=" + event.data.id)
        .then((response) => {
          this.pedidoSingle = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientes() {
      this.loading = true;
      this.$http.get("/clientes").then((response) => {
        this.loading = false;
        this.clientes = response.data.data;
      });
    },
  },
  created() {
    this.getClientes();
  },
};
</script>

<style lang="scss" scoped>
</style>